import React from 'react';

import Style from './titleBanner.module.css'

export const TitleBanner = ({title, content}) => (
  <header className={Style.container}>
    <h1 className={Style.title} dangerouslySetInnerHTML={{__html: title}} />
    <div className={Style.content} dangerouslySetInnerHTML={{__html: content}}/>
  </header>
)
