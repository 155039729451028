import React from 'react'
import Style from './grid.module.css'
import Select from './select.js'
const asFilters = (filters, allowEmpty)=>{
  if(!allowEmpty){
    allowEmpty = true
  }
  let _res = {};
  for(let key in filters) {
    const val = filters[key];
    let items = []
    if(allowEmpty){
      items.push({
        value:"*",
        label: "All",
        isSelected: true
      })
    }
    if (val && val.length){
      items = items.concat(val.map(i => {
        return {
          value: i.id,
          label: i.name,
          isSelected: false
        }
      }))
    }
    _res[key]=items
  }
  return _res
}
export const withGrid = (CardComponent, collection, filters) => {
  /**
   * filter must be an object where the key is a property of collection items
   * the schema would be:
   *  filters: {
   *   property_of_item : [{id:someid, name:some name}]
   *  }
   */
  return class extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        allItems: [...collection],
        filters: asFilters(filters),
        activeFilters: {},
        activeItems: [...collection]
      }
      this.updateFilter = this.updateFilter.bind(this)
    }
    updateFilter(selected, filter){
      let newState = {
        activeFilters:{...this.state.activeFilters}
      }
      if(selected){
        newState.activeFilters[filter]=selected
      }
      if(selected.value==="*"){
        newState.activeItems = this.state.allItems
      }else {
        newState.activeItems = this.state.allItems.filter(item => {
          if(item.hasOwnProperty(filter) && item[filter] && item[filter].length){
            if(item[filter].find(f=>f.id===selected.value)){
              return item
            }
          }
          return false;
        })
      }
      if(this.props.onSelectFilter){
        this.props.onSelectFilter(selected.value,filter)
      }
      this.setState(newState)
    }
    render(){
      return(
        <div className={Style.container}>
          {Object.keys(this.state.filters).length > 0 && 
           <div className={Style.filterContainer}>
               {Object.keys(this.state.filters).map(
                 filter =>
                   <div className={Style.filter}>
                     <Select
                       choices={this.state.filters[filter]}
                       onChange={(selected)=>this.updateFilter(selected,filter)}
                     />
                   </div>
               )}
           </div>
          }
          <div className={Style.gridContainer}>
          {this.state.activeItems.map((item, index) =>(
            <div className={Style.item} key={item.id}>
              <CardComponent {...item}/>
            </div>
          ))}

        </div>
        </div>
      )
    }
  }
}
