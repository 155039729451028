import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'
import Section from 'components/section'
import { withGrid } from '../components/grid/grid';
import {VideoCard} from 'components/cards/mediaCards/videoCard'
import {TitleBanner} from 'components/banners/titleBanner'
import {BgPattern} from 'components/backgrounds'
import BgStyle from 'components/backgrounds/patterns.module.css'
import Style from './videos.module.css'

const VideosTemplate = ({data, ...props}) => {
  const VideosGrid = withGrid(VideoCard, data.videos.nodes);
  return (
    <Layout
      articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
      articleClassName={`${data.wordpressPage.classes}`}
      context = {props.pageContext}
      location = {props.location}
    >
      <TitleBanner title={data.wordpressPage.title} />
      <div className="relative">
        <BgPattern className={`${BgStyle.pattern3} ${Style.bgTop} `}/>
      <Section>
        <VideosGrid />
      </Section>
      </div>
    </Layout>
  )
};


export default VideosTemplate
export const VideosQuery = graphql `
query VideosQuery($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
  }
  videos:allWordpressWpResources(filter:{resource_type:{eq:"video"}}, sort:{order:ASC, fields:[menu_order]}) {
    nodes {
      path
      date(formatString:"LL")
      title
    }
  }
}
`
