import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Img from 'gatsby-image'
import CardStyle from './mediacard.module.css'




export const VideoCard = React.forwardRef( (props, ref) => (
  <div className={CardStyle.container} ref={ref}>


     <div className={CardStyle.media}>
		<ReactPlayer url={props.path} height={261} width="100%" controls={true}/>
     </div>

    <div className="flex items-center justify-between p-1 ">
      <div className="mr-5">
        {props.date &&
         <div className={CardStyle.top}>{props.date}</div>
        }
        <div className={CardStyle.title} dangerouslySetInnerHTML={{__html:props.title}}/>

      </div>
    </div>
  </div>
))
