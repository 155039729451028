import React from 'react'
import Styles from './patterns.module.css'

export const BgPattern = (props) => (
  <div className={`absolute ${props.className}`}

  >

  </div>
)
