import React from 'react'
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames'

import {ReactComponent as ChevronIcon} from 'images/icons/Chevron.svg'

import styles from './selectStyle.module.css'

const DownChevron = ({ innerRef, innerProps }) => (
  <div style={{color: '#654454'}} ref={innerRef} {...innerProps}>
    <ChevronIcon className="fill-current"/>
  </div>
)

const Menu = props => {
  return (
    <components.Menu {...props} className={styles.menu}>
      {props.children}
    </components.Menu>
  )
}



export class StyledSelect extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }
  render() {
    const className = classnames(
      "border-b border-b-2 border-aubergine ",
      this.props.className
    );

    const selectStyles = {
      control: (styles,{isFocused}) => ({
        ...styles,

        // This line disable the blue border
        boxShadow: this.state.open ? '0 2px 7px 3px rgba(0,0,0,0.08)' : "0 0 0 0",
        borderRadius: 0,
        border: 0,
        paddingRight: 10,
        minWidth: 100
        
        

      }),
 
      indicatorSeparator: style =>({display:null}),
      menu: (styles) => ({...styles, borderRadius:0, marginTop:0, borderTop: '2px solid #654454', zIndex:200}),
      option: (styles, {data, isDisabled, isFocused, isSelected, ...rest}) => {
        return {
          ...styles,
          backgroundColor: '#FFF',
          display:isSelected?'none':null,
          fontSize: 14,
          fontStyle: 'italic',
          letterSpacing: 1,
          opacity: isFocused?1:0.6,
          color: '#654454'
        };
      },
      dropdownIndicator: styles => ({...styles, color: '#654454'}),
      singleValue: (provided, state) => ({
        ...provided, 
        fontSize: 14,
        fontStyle: 'italic',
        letterSpacing: 1,
        color: '#654454'
      })
    };

    return(
      
      <Select
        className="select relative"
        classNamePrefix="select"
        isSearchable={false}
        options={this.props.choices}
        styles={selectStyles}
        value={this.props.value}
        onChange={this.props.onChange}
        components={{DropdownIndicator: DownChevron, Menu}}
        onMenuOpen={() => this.setState({open: true})}
        onMenuClose={() => this.setState({open: false})}
        placeholder={this.props.placeholder}
        isClearable={true}
        {...this.props}
      />
      
    )
  }
}

export class StyledAsyncSelect extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      inputValue: '',
    }
  }
  render() {
    const className = classnames(
      "border-b border-b-2 border-aubergine ",
      this.props.className
    );

    const selectStyles = {
      control: (styles,{isFocused}) => ({
        ...styles,

        // This line disable the blue border
        boxShadow: this.state.open ? '0 2px 7px 3px rgba(0,0,0,0.08)' : "0 0 0 0",
        borderRadius: 0,
        border: 0,
        paddingRight: 10,
        

      }),
      input: style => ({...style,minWidth:'100%' }),
      indicatorSeparator: style =>({display:null}),
      menu: (styles) => ({...styles, borderRadius:0, marginTop:0, borderTop: '2px solid #654454', display: this.state.open ? 'block' : 'none'}),
      option: (styles, {data, isDisabled, isFocused, isSelected, ...rest}) => {
        return {
          ...styles,
          backgroundColor: '#FFF',
          display:isSelected?'none':null,
          fontSize: 14,
          fontStyle: 'italic',
          letterSpacing: 1,
          opacity: isFocused?1:0.6,
          color: '#654454'
        };
      },
      dropdownIndicator: styles => ({...styles, color: '#654454'}),
      singleValue: (provided, state) => ({
        ...provided, 
        fontSize: 14,
        fontStyle: 'italic',
        letterSpacing: 1,
        color: '#654454'
      })
    };
    return(
      
      <AsyncSelect
        {...this.props}
        className="select relative"
        classNamePrefix="select"
        isSearchable={this.props.isSearchable}
        isClearable={this.props.isClearable}
        defaultOptions={this.props.defaultOptions}
        loadOptions={this.props.loadOptions}
        styles={selectStyles}
        value={this.props.value}
        noOptionsMessage= {({inputValue})=>{
          if(!inputValue){
            return (<span>start typing to search for options</span>)
          }
          return (<span>no options</span>)

        }}
        onChange={this.props.onChange}
        components={{DropdownIndicator: !!this.props.showChevron?DownChevron:'' , Menu}}
        onMenuOpen={() => {
            this.setState({open: true})
          
        }}
        onMenuClose={() => this.setState({open: false, inputValue: ''})}
        placeholder={this.props.placeholder}
        key={this.props.key}

        
      />
      
    )
  }
}
