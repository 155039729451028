import React from 'react'
import {StyledSelect} from 'components/select'

export default  (props) => {
  return (
    <StyledSelect
      {...props}
      
    />
  )
  }
