import React from 'react'
import Styles from './section.module.css'
import {Link} from 'components/link'

export default (props) => {
  return (
    <section className={Styles.section} style={{maxWidth: props.maxWidth ? props.maxWidth : undefined}}>
      {props.title && 
       <h2 className={Styles.title} dangerouslySetInnerHTML={{__html:props.title}}/>
      }
      <div className={`${Styles.content} ${props.ContentClassName?props.ContentClassName:''}`}>
        {props.children}
      </div>
      <div className={Styles.footer}>
        {props.button &&
         <Link className={Styles.button} to={props.button.url} target={props.button.target}>
           <button  dangerouslySetInnerHTML={{__html:props.button.title}} />
         </Link>
        }
      </div>
    </section>
  )
}
